import { IEstimateCreditLineItemModel } from "./estimate-credit-line-item.model";
import { IEstimateLineItemModel } from "./estimate-line-item.model";

export interface IEstimateModel {
	estimateId: number;
	estimateName: string;
	uuid: string;
	version: number;
	profit?: number;
	profitClassName: string;
	overhead?: number;
	overheadClassName: string;
	materialSalesTax?: number;
	materialSalesTaxClassName: string;
	jobId: number;
	qbwcClassId?: number;
	laborAndMaterialsTotal: number;
	marginAverage: number;
	budgetTotal: number;
	costTotal: number;
	balanceTotal: number;
	actualAverage: number;
	totalLineItems: number;
	totalCredits: number;
	total: number;
	hasUnaccountedForLineItems: boolean;
	isDeleted: boolean;
	syncToQuickbooks: boolean;
	quickbooksEstimateId: string;
	quickbooksInvoiceId: string;
	addToInvoice: boolean;

	estimateLineItems: IEstimateLineItemModel[];
	estimateCreditLineItems: IEstimateCreditLineItemModel[];
}

export class EstimateModel implements IEstimateModel {
	estimateId: number;
	estimateName: string;
	uuid: string;
	version: number;
	profit?: number;
	profitClassName: string;
	overhead?: number;
	overheadClassName: string;
	materialSalesTax?: number;
	materialSalesTaxClassName: string;
	jobId: number;
	qbwcClassId?: number;
	laborAndMaterialsTotal: number;
	marginAverage: number;
	budgetTotal: number;
	costTotal: number;
	balanceTotal: number;
	actualAverage: number;
	totalLineItems: number;
	totalCredits: number;
	total: number;
	hasUnaccountedForLineItems: boolean;
	isDeleted: boolean;
	syncToQuickbooks: boolean;
	quickbooksEstimateId: string;
	quickbooksInvoiceId: string;
	addToInvoice: boolean;

	estimateLineItems: IEstimateLineItemModel[];
	estimateCreditLineItems: IEstimateCreditLineItemModel[];
}