import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { UtilsService } from "./utils/utils.service";
import { IDocumentModel, IPayableModel, PayableModel, IPayableLineItemModel, PayableLineItemModel, IPayableGridModel, IDropdownModel } from "@models";

@Injectable()
export class PayablesService {
	constructor(private httpService: HttpService) {
	}

	getNewPayableModel(): IPayableModel {
		let newPayableModel = new PayableModel();
		newPayableModel.uuid = UtilsService.newGuid();
		newPayableModel.payableLineItems = [];

		return newPayableModel;
	}

	getNewPayableLineItemModel(): IPayableLineItemModel {
		let newPayableLineItemModel = new PayableLineItemModel();
		newPayableLineItemModel.uuid = UtilsService.newGuid();
		newPayableLineItemModel.amount = 0;

		return newPayableLineItemModel;
	}

	getPayable(payableId): Promise<IPayableModel> {
		return this.httpService.get("/payables/getPayable", { payableId: payableId });
	}

	getPayableForGrid(payableId): Promise<IPayableModel> {
		return this.httpService.get("/payables/getPayableForGrid", { payableId: payableId });
	}

	getPayablesInBatch(batchNumber): Promise<IPayableGridModel[]> {
		return this.httpService.get("/payables/getPayablesInBatch", { batchNumber: batchNumber });
	}

	getActiveJobsForPayables(): Promise<IDropdownModel[]> {
		return this.httpService.get("/jobs/getActiveJobsForPayables");
	}

	async addNewPayable(): Promise<IPayableModel> {
		return await this.httpService.post("/payables/addNewPayable", null);
	}

	async addNewPayableByDocument(file: File): Promise<IPayableModel> {
		try {
			let formData: FormData = new FormData();
			formData.append('file', file, file.name);

			let returnFileModels = await this.httpService.postMultipart("/payables/addNewPayableByDocument", formData);
			return Promise.resolve(returnFileModels);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async addNewPayableByEnc64Image(enc64Image: string): Promise<IPayableModel> {
		try {
			const formData = new FormData();
			formData.append('enc64', enc64Image);

			let returnFileModels = await this.httpService.postMultipart("/payables/addNewPayableByEnc64Image", formData);
			return Promise.resolve(returnFileModels);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async updatePayable(payableModel: IPayableModel): Promise<IPayableModel> {
		try {
			return await this.httpService.post("/payables/updatePayable", payableModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	updateMobileUploadUserId(payableId: number, userId?: number): Promise<number> {
		return this.httpService.patch(`/payables/updateMobileUploadUserId?payableId=${payableId}`, userId);
	}

	updateVendorId(payableId: number, vendorId?: number): Promise<number> {
		return this.httpService.patch(`/payables/updateVendorId?payableId=${payableId}`, vendorId);
	}

	updateSubcontractorId(payableId: number, subcontractorId?: number): Promise<number> {
		return this.httpService.patch(`/payables/updateSubcontractorId?payableId=${payableId}`, subcontractorId);
	}

	updateJobId(payableId: number, jobId?: number): Promise<number> {
		return this.httpService.patch(`/payables/updateJobId?payableId=${payableId}`, jobId);
	}

	updateEstimatorId(payableId: number, estimatorId?: number): Promise<number> {
		return this.httpService.patch(`/payables/updateEstimatorId?payableId=${payableId}`, estimatorId);
	}

	clearEstimator(payableId: number): Promise<number> {
		return this.httpService.patch(`/payables/clearEstimator?payableId=${payableId}`, {});
	}

	updateAccountingUserId(payableId: number, accountingUserId?: number): Promise<number> {
		return this.httpService.patch(`/payables/updateAccountingUserId?payableId=${payableId}`, accountingUserId);
	}

	updatePaymentType(payableId: number, paymentType: string): Promise<number> {
		return this.httpService.patch(`/payables/updatePaymentType?payableId=${payableId}`, { paymentType });
	}

	updateCreditCard(payableId: number, creditCard: string): Promise<number> {
		return this.httpService.patch(`/payables/updateCreditCard?payableId=${payableId}`, { creditCard });
	}

	updateInvoiceNumber(payableId: number, invoiceNumber: string): Promise<number> {
		return this.httpService.patch(`/payables/updateInvoiceNumber?payableId=${payableId}`, { invoiceNumber });
	}

	updateInvoiceDate(payableId: number, invoiceDate?: Date): Promise<number> {
		return this.httpService.patch(`/payables/updateInvoiceDate?payableId=${payableId}`, { invoiceDate });
	}

	updateInvoiceAmount(payableId: number, invoiceAmount?: number): Promise<number> {
		return this.httpService.patch(`/payables/updateInvoiceAmount?payableId=${payableId}`, { invoiceAmount });
    }

    updateInvoiceMemo(payableId: number, invoiceMemo: string): Promise<number> {
        return this.httpService.patch(`/payables/updateInvoiceMemo?payableId=${payableId}`, { invoiceMemo });
    }


	deletePayable(payableId: number, uuid: string, isDuplicate: boolean = false): Promise<boolean> {
		try {
			const params = {
				payableId,
				uuid,
				isDuplicate
			}
			return this.httpService.delete("/payables/deletePayable", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	checkDuplicateInvoiceNumber(payableId: number, vendorId: number, invoiceNumber: string): Promise<boolean> {
		try {
			const params = {
				payableId: payableId,
				vendorId: vendorId,
				invoiceNumber: invoiceNumber
			}

			return this.httpService.get("/payables/checkDuplicateInvoiceNumber", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	updatePayableLineItem(payableId: number, lineItem: IPayableLineItemModel) {
		try {
			return this.httpService.post("/payables/updatePayableLineItem?payableId=" + payableId, lineItem);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	deletePayableLineItem(payableId: number, lineItem: IPayableLineItemModel) {
		try {
			const params = {
				payableId,
				payableLineItemId: lineItem.payableLineItemId,
				uuid: lineItem.uuid
			}
			return this.httpService.delete("/payables/deletePayableLineItem", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	addToNextBatch(payableId: number, uuid: string): Promise<IPayableGridModel> {
		try {
			const params = {
				payableId: payableId,
				uuid: uuid
			}
			return this.httpService.get("/payables/addToNextBatch", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	removeFromBatch(payableId: number, uuid: string): Promise<IPayableGridModel> {
		try {
			const params = {
				payableId: payableId,
				uuid: uuid
			}
			return this.httpService.get("/payables/removeFromBatch", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	manualQBSync(payableId: number, uuid: string): Promise<IPayableGridModel> {
		try {
			const params = {
				payableId: payableId,
				uuid: uuid
			}
			return this.httpService.get("/payables/manualQBSync", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	removeManualQBSync(payableId: number, uuid: string): Promise<IPayableGridModel> {
		try {
			const params = {
				payableId: payableId,
				uuid: uuid
			}
			return this.httpService.get("/payables/removeManualQBSync", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	runBatch(batchNumber: number): Promise<IPayableGridModel[]> {
		try {
			const params = {
				batchNumber: batchNumber
			}

			return this.httpService.get("/payables/runBatch", params);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	rotateImage(documentId: number, rotation: number): Promise<IDocumentModel> {
		const params = {
			rotation: rotation
		}

		return this.httpService.patch(`/payables/rotateImage?documentId=${documentId}`, rotation);
	}
}