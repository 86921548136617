import { IAppointmentResourceModel } from "./appointment-resource.model"
import { IAppointmentLaborCodeModel } from "./appointment-labor-code.model"
import { IAppointmentReminderModel } from './appointment-reminder.model';

export enum AppointmentTypes { ServiceTech = 1, Subcontractor = 2, Event = 3, Employee = 4 }

export interface IAppointmentModel {
	appointmentId: number;
	uuid: string;
	version: number;
	appointmentType: AppointmentTypes;
	appointmentGroupUuid: string;
	subcontractorId?: number;
	subcontractorDisplayName: string;
	subcontractorScheduleColor: string;
	isOnClipboard: boolean;
	clipboardUserId?: number;
	clipboardUserFullName: string;
	isPending: boolean;
	isTimeBlock: boolean;
	isComplate: boolean;
	jobId?: number;
	jobName: string;
	jobCustomerDisplayName: string;
	workOrderId?: number;
	workOrderName: string;
	startDate: Date;
	endDate: Date;
	duration: number;
	employeeOrSubcontractor: string;
	scratchPad: string;
	appointmentClass: string;
	sendEmailReminder: boolean;
	sendTextReminder: boolean;
	inTransitSendDate?: Date;
	multiDayDates: Date[];
	isDirty: boolean;
	isDeleted: boolean;
	resources: IAppointmentResourceModel[];
	laborCodes: IAppointmentLaborCodeModel[];
	appointmentReminders: IAppointmentReminderModel[];
}

export class AppointmentModel implements IAppointmentModel {
	appointmentId: number;
	uuid: string;
	version: number;
	appointmentType: AppointmentTypes;
	appointmentGroupUuid: string;
	subcontractorId?: number;
	subcontractorDisplayName: string;
	subcontractorScheduleColor: string;
	isOnClipboard: boolean;
	clipboardUserId?: number;
	clipboardUserFullName: string;
	isPending: boolean;
	isTimeBlock: boolean;
	isSubcontractor: boolean;
	isComplate: boolean;
	jobId?: number;
	jobName: string;
	jobCustomerDisplayName: string;
	workOrderId?: number;
	workOrderName: string;
	startDate: Date;
	endDate: Date;
	duration: number;
	employeeOrSubcontractor: string;
	scratchPad: string;
	appointmentClass: string;
	sendEmailReminder: boolean;
	sendTextReminder: boolean;
	inTransitSendDate?: Date;
	multiDayDates: Date[];
	isDirty: boolean;
	isDeleted: boolean;
	resources: IAppointmentResourceModel[];
	laborCodes: IAppointmentLaborCodeModel[];
	appointmentReminders: IAppointmentReminderModel[];
}

