import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { UtilsService } from "./utils/utils.service";
import { IEquipmentModel, EquipmentModel, IEquipmentGridModel, IEquipmentByTypeModel, IEquipmentCheckoutByTypeModel } from "@models";
import * as moment from "moment";

@Injectable()
export class EquipmentService {
	constructor(private httpService: HttpService) { }

	getNewEquipmentModel(): IEquipmentModel {
		var equipmentModel: IEquipmentModel = new EquipmentModel();
		equipmentModel.equipmentId = 0;
		equipmentModel.uuid = UtilsService.newGuid();
		equipmentModel.equipmentTypeId = null;
		equipmentModel.equipmentTypeDescription = null;
		equipmentModel.documentFolderUuid = UtilsService.newGuid();
		equipmentModel.active = true;

		return equipmentModel;
	}

	async getEquipment(equipmentId: number): Promise<IEquipmentModel> {
		try {
			return Promise.resolve(await this.httpService.get("/equipment/getEquipment", { equipmentId: equipmentId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}

	async getEquipmentForGrid(equipmentId: number): Promise<IEquipmentGridModel> {
		try {
			return Promise.resolve(await this.httpService.get("/equipment/getEquipmentForGrid", { equipmentId: equipmentId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getEquipmentForInfiniteScroll(pageCount: number, equipmentTypeId: number = 0, searchString: string = ""): Promise<IEquipmentModel[]> {
		try {
			const params = {
				pageCount: pageCount,
				equipmentTypeId: equipmentTypeId,
				searchString: searchString
			}

			return Promise.resolve(await this.httpService.get("/equipment/getEquipmentForInfiniteScroll", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getEquipmentForJob(jobId: number, mitigationRoomId: number = null): Promise<IEquipmentByTypeModel[]> {
		const params = {
			jobId: jobId,
			mitigationRoomId: mitigationRoomId
		}

		if (!mitigationRoomId)
			delete params.mitigationRoomId;

		return this.httpService.get("/equipment/getEquipmentForJob", params);
	}

	async getAllEquipmentByType(showOutOfService: boolean, showInactive: boolean): Promise<IEquipmentByTypeModel[]> {
		try {
			let params = {
				showOutOfService,
				showInactive
			}
			return Promise.resolve(await this.httpService.get("/equipment/getAllEquipmentByType", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getEquipmentByType(equipmentTypeId, showOutOfService: boolean, showInactive: boolean): Promise<IEquipmentGridModel[]> {
		try {
			let params = {
				equipmentTypeId,
				showOutOfService,
				showInactive
			}
			return Promise.resolve(await this.httpService.get("/equipment/getEquipmentByType", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async equipmentSearch(searchString: string, showInactive: boolean): Promise<IEquipmentGridModel[]> {
		try {
			let params = {
				searchString,
				showInactive
			}
			return Promise.resolve(await this.httpService.get("/equipment/equipmentSearch", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getAvailableEquipment(): Promise<IEquipmentByTypeModel[]> {
		try {
			return Promise.resolve(await this.httpService.get("/equipment/getAvailableEquipment"));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async isEquipmentAvailable(equipmentId: number): Promise<string> {
		try {
			let equipmentAssignedBy = await this.httpService.get("/equipment/isEquipmentAvailable", { equipmentId: equipmentId });
			if (!equipmentAssignedBy)
				return Promise.resolve(null);

			let assignedBy = equipmentAssignedBy.item1;
			let assignedDate = moment(equipmentAssignedBy.item2).format("M/D/YYYY h:mma");
			return Promise.resolve(assignedBy + " On " + assignedDate);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async updateProfilePicture(equipmentId: number, enc64Image: string): Promise<string> {
		try {
			let params = {
				equipmentId: equipmentId,
				enc64Image: enc64Image
			}

			const response = await this.httpService.post("/equipment/updateProfilePicture", params);

			return response.profilePictureUrl;
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	public async updateEquipment(equipmentModel: IEquipmentModel): Promise<IEquipmentModel> {
		if (equipmentModel.equipmentId === 0)
			return this.httpService.post("/equipment/addEquipment", equipmentModel);
		else {
			return this.httpService.post("/equipment/updateEquipment", equipmentModel);
		}
	}

	public async updateEquipmentStatus(equipmentId: number, equipmentStatusId: number): Promise<IEquipmentModel> {
		try {
			let params = {
				equipmentId: equipmentId,
				equipmentStatusId: equipmentStatusId
			}

			return Promise.resolve(await this.httpService.post("/equipment/updateEquipmentStatus", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async updateScratchPad(equipmentId: number, scratchPad: string): Promise<IEquipmentModel> {
		try {
			let params = {
				equipmentId: equipmentId,
				scratchPad: scratchPad
			}

			return Promise.resolve(await this.httpService.post("/equipment/updateScratchPad", params));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getEquipmentCheckoutsByTypeForJob(jobId: number): Promise<IEquipmentCheckoutByTypeModel[]> {
		return this.httpService.get(`/equipment/getEquipmentCheckoutsByTypeForJob?jobId=${jobId}`);
	}
}
