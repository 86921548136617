import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from "@angular/core";
import { IContactModel, IAddressModel, IPhoneNumberModel, IEmailAddressModel } from "@models";
import { SlickToastService } from "slick-components";
import { UtilsService, ContactsService, SleepService } from "@services";
import { ContactEditDialogComponent } from "../contact-edit/contact-edit-dialog.component";

@Component({
	selector: "contact-display",
	templateUrl: "contact-display.component.html"
})
export class ContactDisplayComponent implements OnChanges {
	@Input() header: string;
	@Input() contactModel: IContactModel;
	@Input() contactId: number;
	@Input() userId: number;
	@Input() canEditContact: boolean = false;
	@Input() canRemoveContact: boolean = false;
	@Input() hideAddress: boolean = false;
	@Output() onContactRemoved: EventEmitter<IContactModel> = new EventEmitter();

	@ViewChild("contactEditDialogRef", { static: true }) contactEditDialogRef: ContactEditDialogComponent;

	dialogContactModel: IContactModel;

	constructor(private readonly contactsService: ContactsService,
		private readonly slickToastService: SlickToastService) {
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.contactId) {
			this.contactModel = null;
			if (changes.contactId.currentValue)
				this.contactModel = await this.contactsService.getContact(changes.contactId.currentValue);
		}

		if (changes.userId) {
			this.contactModel = null;
			if (changes.userId.currentValue)
				this.contactModel = await this.contactsService.getContactByUserId(changes.userId.currentValue);
		}

		if (changes.canEditContact)
			this.canEditContact = (this.canEditContact.toString().toLowerCase() === 'true') ? true : false;

		if (changes.hideAddress)
			this.hideAddress = (this.hideAddress.toString().toLowerCase() === 'false') ? false : true;
	}

	editContact() {
		this.dialogContactModel = UtilsService.clone(this.contactModel);
		this.contactEditDialogRef.showDialog();
	}

	onContactSave(contactModel: IContactModel) {
		this.contactModel = contactModel;
	}

	removeContact() {
		if (this.onContactRemoved)
			this.onContactRemoved.emit(this.contactModel);
	}

	async emailToClipboard() {
		await UtilsService.copyToClipboard(this.contactModel.defaultEmailAddress.emailAddress);
		this.slickToastService.showSuccess("Email address copied to the clipboard", 1000);
	}
}
