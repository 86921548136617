import { Injectable } from "@angular/core";

export class KeywordCategories {
	category: string;
	keywords: string[];
}


@Injectable()
export class TemplatesService {
	companyKeywords: KeywordCategories = { category: 'Company', keywords: ['%COMPANY_LOGO%', '%COMPANY_NAME%', '%COMPANY_FULL_ADDRESS%', '%COMPANY_CITY_STATE_POSTAL%', '%COMPANY_ADDRESS1%', '%COMPANY_ADDRESS2%', '%COMPANY_CITY%', '%COMPANY_STATE%', '%COMPANY_POSTAL_CODE%', '%COMPANY_CELL_PHONE%', '%COMPANY_ALT1_PHONE%', '%COMPANY_ALT2_PHONE%', '%COMPANY_EMAIL%'] };
	estimateKeywords: KeywordCategories = { category: 'Estimate', keywords: ['%ESTIMATE_NAME%', '%ESTIMATE_TOTAL%'] };
	customerKeywords: KeywordCategories = { category: 'Customer', keywords: ['%FIRST_NAME%', '%LAST_NAME%', '%FULL_NAME%', '%CUSTOMER_COMPANY_NAME%', '%FULL_ADDRESS%', '%ADDRESS1%', '%ADDRESS2%', '%CITY%', '%STATE%', '%POSTAL_CODE%', '%CELL_PHONE%', '%ALT1_PHONE%', '%ALT2_PHONE%', '%EMAIL%'] };
	invoiceKeywords: KeywordCategories = { category: 'Invoice', keywords: ['%INVOICE_NUMBER%', '%PO_NUMBER%', '%INVOICE_DUE_DATE%', '%INVOICE_TOTAL%', '%INVOICE_LINE_ITEMS%', '%CREDIT_LINE_ITEMS%', '%AMOUNT_DUE%', '%APPLIED_TOTAL%', '%ONLINE_PAYMENT_LINK%'] };
	paymentKeywords: KeywordCategories = { category: 'Payment', keywords: ['%PAYMENT_DATE%', '%PAYMENT_REF_NUMBER%', '%PAYMENT_TYPE%', '%PAYMENT_AMOUNT%', '%PAYMENT_REMAINING%', '%APPLIED_PAYMENTS%'] };
	jobKeywords: KeywordCategories = { category: 'Job', keywords: ['%JOB_NAME%', '%JOB_STATUS%', '%ESTIMATOR_NAME%', '%PRODUCTION_MANAGER%', '%SPECIALTY_MANAGER%', '%DAMAGE_TYPE%', '%CLAIM_NUMBER%', '%DATE_OF_LOSS%', '%YEAR_BUILT%', '%PRIMARY_FIRST_NAME%', '%PRIMARY_LAST_NAME%', '%PRIMARY_FULL_NAME%', '%PRIMARY_COMPANY_NAME%', '%PRIMARY_FULL_ADDRESS%', '%PRIMARY_ADDRESS1%', '%PRIMARY_ADDRESS2%', '%PRIMARY_CITY%', '%PRIMARY_STATE%', '%PRIMARY_POSTAL_CODE%', '%PRIMARY_CELL_PHONE%', '%PRIMARY_ALT1_PHONE%', '%PRIMARY_ALT2_PHONE%', '%PRIMARY_EMAIL%'] };
	constructor() { }

	getEstimateKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.estimateKeywords, this.jobKeywords];
	}

	getInvoiceKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.invoiceKeywords, this.jobKeywords];
	}

	getPaymentRequestKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.paymentKeywords];
	}

	getPaymentReceiptKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.paymentKeywords];
	}
}
