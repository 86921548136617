import { Injectable } from "@angular/core";
import { IEstimateModel, EstimateModel, IEstimateLineItemModel, EstimateLineItemModel, IXactimateLineItemsModel, IEstimateCreditLineItemModel, EstimateCreditLineItemModel, IEmailerModel } from "@models";
import { UtilsService } from "./utils/utils.service";
import { HttpService } from "./utils/http.service";
import { ISlickFileModel } from "slick-components";

@Injectable()
export class EstimatesService {
	constructor(private httpService: HttpService) { }

	getNewEstimateModel(jobId: number): IEstimateModel {
		let newModel = new EstimateModel;
		newModel.estimateId = 0;
		newModel.uuid = UtilsService.newGuid();
		newModel.jobId = jobId;
		newModel.estimateLineItems = new Array<IEstimateLineItemModel>();
		newModel.estimateCreditLineItems = new Array<IEstimateCreditLineItemModel>();

		return newModel;
	}

	getNewEstimateLineItemModel(estimateId: number): IEstimateLineItemModel {
		const newModel = new EstimateLineItemModel;
		newModel.estimateLineItemId = 0;
		newModel.estimateId = estimateId;
		newModel.uuid = UtilsService.newGuid();
		newModel.itemId = 0;
		newModel.itemCode = '';
		newModel.itemDescription = '';
		newModel.laborAndMaterials = 0;
		newModel.budget = 0;
		newModel.marginPercent = 0;
		newModel.cost = 0;
		newModel.balance = 0;
		newModel.actualPercent = 0;

		return newModel;
	}

	getNewCreditLineItemModel(): IEstimateCreditLineItemModel {
		const newModel = new EstimateCreditLineItemModel();
		newModel.estimateCreditLineItemId = 0;
		newModel.uuid = UtilsService.newGuid();
		newModel.itemCode = '';
		newModel.description = '';
		newModel.quantity = 1;
		newModel.amount = 0;
		newModel.total = 0;
		newModel.isNewCreditLineItem = true;

		return newModel;

	}

	async getEstimate(estimateId: number): Promise<IEstimateModel> {
		return Promise.resolve(await this.httpService.get("/estimates/getEstimate", { estimateId: estimateId }));
	}

	calcTotals(estimateModel: IEstimateModel): IEstimateModel {
		estimateModel.laborAndMaterialsTotal = 0;
		estimateModel.marginAverage = 0;
		estimateModel.budgetTotal = 0;
		estimateModel.costTotal = 0;
		estimateModel.balanceTotal = 0;
		estimateModel.actualAverage = 0;
		estimateModel.totalCredits = 0;

		estimateModel.estimateLineItems.forEach(li => {
			estimateModel.laborAndMaterialsTotal += li.laborAndMaterials;
			estimateModel.marginAverage += li.marginPercent;
			estimateModel.budgetTotal += li.budget;
			estimateModel.costTotal += li.cost;
			estimateModel.balanceTotal += li.balance;
		})
		if (estimateModel.estimateLineItems && estimateModel.estimateLineItems.length > 0)
			estimateModel.marginAverage = estimateModel.marginAverage / estimateModel.estimateLineItems.length;

		estimateModel.actualAverage = (1 - (estimateModel.costTotal / estimateModel.laborAndMaterialsTotal)) * 100;;

		estimateModel.total = estimateModel.laborAndMaterialsTotal;

		if (estimateModel.materialSalesTax) {
			estimateModel.total += estimateModel.materialSalesTax;
		}

		if (estimateModel.overhead) {
			estimateModel.total += estimateModel.overhead;
		}

		if (estimateModel.profit) {
			estimateModel.total += estimateModel.profit;
		}

		estimateModel.estimateCreditLineItems.forEach(li => {
			if (!li.isNewCreditLineItem) {
				estimateModel.totalCredits += li.total;
				estimateModel.total -= li.total;
			}
		});

		estimateModel.laborAndMaterialsTotal = UtilsService.round(estimateModel.laborAndMaterialsTotal);
		estimateModel.marginAverage = UtilsService.round(estimateModel.marginAverage);
		estimateModel.budgetTotal = UtilsService.round(estimateModel.budgetTotal);
		estimateModel.costTotal = UtilsService.round(estimateModel.costTotal);
		estimateModel.balanceTotal = UtilsService.round(estimateModel.balanceTotal);
		estimateModel.actualAverage = UtilsService.round(estimateModel.actualAverage);
		estimateModel.total = UtilsService.round(estimateModel.total);

		return estimateModel;
	}

	calcGrandTotal(estimateModels: IEstimateModel[]): IEstimateModel {
		let estimateModel = new EstimateModel();
		estimateModel.laborAndMaterialsTotal = 0;
		estimateModel.budgetTotal = 0;
		estimateModel.marginAverage = 0;
		estimateModel.costTotal = 0;
		estimateModel.balanceTotal = 0;
		estimateModel.actualAverage = 0;
		estimateModel.total = 0;

		if (estimateModels && estimateModels.length > 0) {
			estimateModels.forEach(e => {
				estimateModel.laborAndMaterialsTotal += e.laborAndMaterialsTotal;
				estimateModel.budgetTotal += e.budgetTotal;
				estimateModel.marginAverage += e.marginAverage;
				estimateModel.costTotal += e.costTotal;
				estimateModel.balanceTotal += e.balanceTotal;
				estimateModel.totalCredits += e.totalCredits;
				estimateModel.total += e.total;
			})

			if (estimateModel.materialSalesTax) 
				estimateModel.laborAndMaterialsTotal += estimateModel.materialSalesTax;

			if (estimateModel.overhead) 
				estimateModel.laborAndMaterialsTotal += estimateModel.overhead;

			if (estimateModel.profit) 
				estimateModel.laborAndMaterialsTotal += estimateModel.profit;


			estimateModel.marginAverage = estimateModel.marginAverage / estimateModels.length;
			estimateModel.actualAverage =  (1 - (estimateModel.costTotal / estimateModel.laborAndMaterialsTotal)) * 100;

			estimateModel.laborAndMaterialsTotal = UtilsService.round(estimateModel.laborAndMaterialsTotal);
			estimateModel.marginAverage = UtilsService.round(estimateModel.marginAverage);
			estimateModel.budgetTotal = UtilsService.round(estimateModel.budgetTotal);
			estimateModel.costTotal = UtilsService.round(estimateModel.costTotal);
			estimateModel.balanceTotal = UtilsService.round(estimateModel.balanceTotal);
			estimateModel.actualAverage = UtilsService.round(estimateModel.actualAverage);
			estimateModel.total = UtilsService.round(+estimateModel.total);
		}

		return estimateModel;
	}


	async getEstimatesForJob(jobId: number): Promise<IEstimateModel[]> {
		return Promise.resolve(await this.httpService.get("/estimates/getEstimatesForJob", { jobId: jobId }));
	}

	async updateEstimate(estimateModel: IEstimateModel): Promise<IEstimateModel> {
		try {
			// Scrub the numbers to make sure none are null
			estimateModel.estimateLineItems.forEach(li => {
				li.laborAndMaterials = li.laborAndMaterials || 0;
				li.budget = li.budget || 0;
				li.marginPercent = li.marginPercent || 0;
				li.cost = li.cost || 0;
				li.balance = li.balance || 0;
				li.actualPercent = li.actualPercent || 0;
			});

			if (estimateModel.estimateId === 0) {
				let returnModel = await this.httpService.post("/estimates/addEstimate", estimateModel);
				return Promise.resolve(returnModel);
			}
			else {
				return Promise.resolve(await this.httpService.post("/estimates/updateEstimate", estimateModel));
			}
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async deleteEstimate(estimateModel: IEstimateModel): Promise<boolean> {
		return Promise.resolve(await this.httpService.delete("/estimates/deleteEstimate", { estimateId: estimateModel.estimateId, uuid: estimateModel.uuid }));
	}

	async generateEstimatePDF(estimateModel: IEstimateModel): Promise<string> {
		const response = await this.httpService.get("/estimates/generateEstimatePDF", { estimateId: estimateModel.estimateId });
		return response.url;
	}

	async saveEstimatePDF(estimateModel: IEstimateModel): Promise<boolean> {
		return Promise.resolve(await this.httpService.get("/estimates/saveEstimatePDF", { estimateId: estimateModel.estimateId }));
	}

	async getEstimatePDF(url: string, title: string): Promise<Blob> {
		return Promise.resolve(await this.httpService.getPdf(url, title));
	}

	async createEmailSentNote(emailUuid: string, estimateId: number): Promise<boolean> {
		return Promise.resolve(await this.httpService.get("/estimates/createEmailSentNote", { emailUuid: emailUuid, estimateId: estimateId }));
	}

	async importLineItemsFromXactimatePDF(file: File): Promise<IXactimateLineItemsModel> {
		let formData: FormData = new FormData();

		formData.append('file', file, file.name);

		return Promise.resolve(await this.httpService.postMultipart("/estimates/importLineItemsFromXactimatePDF", formData));
	}

	async importXactimatePDF(estimateId: number, file: File): Promise<IEstimateLineItemModel[]> {
		let formData: FormData = new FormData();

		formData.append('file', file, file.name);

		return Promise.resolve(await this.httpService.postMultipart("/estimates/importXactimatePDF?estimateId=" + estimateId, formData));
	}

	async getEstimatePhotos(estimateId: number) {
		try {
			return Promise.resolve(await this.httpService.get("/estimates/getEstimatePhotos", { estimateId: estimateId }));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getEstimateEmail(estimateModel: IEstimateModel): Promise<IEmailerModel> {
		return this.httpService.get("/estimates/getEstimateEmail", { estimateId: estimateModel.estimateId });
	}

	async sendEstimateEmail(estimateId: number, emailerModel: IEmailerModel): Promise<IEmailerModel> {
		return this.httpService.post(`/estimates/sendEstimateEmail?estimateId=${estimateId}`, emailerModel);
	}

}