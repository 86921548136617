<div *ngIf="jobModel" class="container-fluid">
    <div class="form-group row">
        <div class="col-6">
            <div *ngIf="jobUuid === jobModel.uuid" class="d-flex">
                <slick-tabs [hideBorder]="true" [(tabIndex)]="customerTabIndex">
                    <slick-tab header="Primary Customer">
                        <div class="px-1">
                            <div class="row form-group mt-2">
                                <div *ngIf="(useJobCustomerSearch === true && showAddCustomer === false) || (useJobCustomerSearch === false && jobModel.jobId > 0)" class="col-12">
                                    <customer-search [(ngModel)]="jobModel.primaryCustomer"
                                                     (onAdd)="onAddPrimaryCustomer($event)"
                                                     (onSelect)="onPrimaryCustomerSelect($event)"></customer-search>
                                    <div class="ms-1">
                                        <contact-display *ngIf="jobModel.primaryCustomer"
                                                         [contactModel]="jobModel.primaryCustomer.contact"></contact-display>
                                    </div>
                                    <form-error *ngIf="!jobModel.primaryCustomer.customerId && isSubmitted === true">Primary Customer is required</form-error>
                                    <form-error *ngIf="(!jobModel.primaryCustomer.contact.firstName || !jobModel.primaryCustomer.contact.lastName) && !jobModel.primaryCustomer.contact.companyName && isSubmitted === true">First and Last Name or Company Name is required</form-error>
                                </div>

                                <div *ngIf="(useJobCustomerSearch === true && showAddCustomer === true) || (useJobCustomerSearch === false && jobModel.jobId === 0)" class="col-12 mt-2">

                                    <customer-search [(ngModel)]="jobModel.primaryCustomer"
                                                     [showButtons]="false"
                                                     (onAdd)="onAddPrimaryCustomer($event)"
                                                     (onSelect)="onPrimaryCustomerSelect($event)"></customer-search>

                                    <div class="mb-2"></div>

                                    <div *ngIf="showCompanyName === true" class="form-group row">
                                        <div class="col-6">
                                            <input class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.firstName" placeholder="First Name" autocomplete="off" (keypress)="onNameChanged($event)" />
                                        </div>
                                        <div class="col-6">
                                            <input class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.lastName" (keypress)="onNameChanged($event)" placeholder="Last Name" autocomplete="off" (blur)="checkExistingCustomers()" />
                                        </div>
                                    </div>
                                    <div *ngIf="showCompanyName === false" class="form-group row">
                                        <div class="col-6">
                                            <input class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.firstName" [slick-validation-indicator]="jobModel.primaryCustomer.contact.firstName" slickValidationType="error" placeholder="First Name" autocomplete="off" (keypress)="onNameChanged($event)" />
                                            <form-error *ngIf="!jobModel.primaryCustomer.contact.firstName && isSubmitted === true">First Name is required</form-error>
                                        </div>
                                        <div class="col-6">
                                            <input class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.lastName" [slick-validation-indicator]="jobModel.primaryCustomer.contact.lastName" slickValidationType="error" (keypress)="onNameChanged($event)" (blur)="checkExistingCustomers()" placeholder="Last Name" autocomplete="off" />
                                            <form-error *ngIf="!jobModel.primaryCustomer.contact.lastName && isSubmitted === true">Last Name is required</form-error>
                                        </div>
                                    </div>
                                    <div *ngIf="showCompanyName === false" style="line-height: 10px; height: 10px; position: relative; top: -6px">
                                        <button type="button" class="btn btn-link" (click)="showCompanyName = !showCompanyName" style="font-size: 10px;">Show Company Name</button>
                                    </div>
                                    <div *ngIf="showCompanyName === true" class="form-group row">
                                        <div class="col-12">
                                            <input class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.companyName" [slick-validation-indicator]="jobModel.primaryCustomer.contact.companyName" slickValidationType="error" placeholder="Company Name" autocomplete="off" />
                                            <form-error *ngIf="showCompanyName === true && !jobModel.primaryCustomer.contact.companyName && isSubmitted === true">Company Name is required</form-error>
                                        </div>
                                    </div>
                                    <div *ngIf="showCompanyName === true" style="line-height: 10px; height: 10px; position: relative; top: -6px">
                                        <button type="button" class="btn btn-link" (click)="showCompanyName = !showCompanyName" style="font-size: 10px;">Hide Company Name</button>
                                    </div>

                                    <address [(ngModel)]="jobModel.primaryCustomer.contact.defaultAddress" [showPlaceholder]="true"></address>
                                    <div *ngIf="showAlt2 === false && !jobModel.primaryCustomer.contact.defaultAlt2.phoneNumber" class="form-group row" style="margin-top: -10px;">
                                        <div class="col-12 d-flex">
                                            <div class="me-1"><input type="text" class="form-control" [slick-validation-indicator]="jobModel.primaryCustomer.contact.defaultCell.phoneNumber" slickValidationType="warning" [(ngModel)]="jobModel.primaryCustomer.contact.defaultCell.phoneNumber" placeholder="Cell" autocomplete="off" /></div>
                                            <div class="me-1"><input type="text" class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.defaultAlt1.phoneNumber" placeholder="Alt 1" /></div>
                                            <div class="me-1"><button type="button" class="btn btn-primary" (click)="showAlt2 = true"><i class="far fa-plus"></i></button></div>
                                        </div>
                                    </div>
                                    <div *ngIf="showAlt2 === true" class="form-group row" style="margin-top: -10px;">
                                        <div class="col-12 d-flex">
                                            <div class="me-1"><input type="text" [slick-validation-indicator]="jobModel.primaryCustomer.contact.defaultCell.phoneNumber" slickValidationType="warning" class="form-control me-1" [(ngModel)]="jobModel.primaryCustomer.contact.defaultCell.phoneNumber" placeholder="Cell" autocomplete="off" /></div>
                                            <div class="me-1"><input type="text" class="form-control me-1" [(ngModel)]="jobModel.primaryCustomer.contact.defaultAlt1.phoneNumber" placeholder="Alt 1" /></div>
                                            <div><input type="text" class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.defaultAlt2.phoneNumber" placeholder="Alt 2" /></div>
                                        </div>

                                    </div>
                                    <div class="form-group row">
                                        <div class="col-12">
                                            <input type="text" [slick-validation-indicator]="jobModel.primaryCustomer.contact.defaultEmailAddress.emailAddress" slickValidationType="warning" class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.defaultEmailAddress.emailAddress" placeholder="Primary Email Address..." autocomplete="off" />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-12">
                                            <input type="text" class="form-control" [(ngModel)]="jobModel.primaryCustomer.contact.secondaryEmailAddress.emailAddress" placeholder="Secondary Email Address..." autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slick-tab>
                    <slick-tab header="Billing Customer">
                        <div class="ps-2 pe-1 mt-2">
                            <input type="checkbox" [(ngModel)]="jobModel.billingIsSameAsPrimary" (click)="setBillingSameAsPrimary()" /> Same as primary customer
                            <div *ngIf="jobModel.billingIsSameAsPrimary" class="row form-group">
                                <contact-display *ngIf="jobModel.primaryCustomer"
                                                 [contactModel]="jobModel.primaryCustomer.contact"></contact-display>
                            </div>
                            <div *ngIf="!jobModel.billingIsSameAsPrimary" class="row form-group">
                                <div *ngIf="showAddBilling === false" class="col-12">
                                    <customer-search [(ngModel)]="jobModel.billingCustomer"
                                                     [alwaysAllowAddNew]="true"
                                                     (onAdd)="onAddBillingCustomer($event)"
                                                     (onSelect)="onBillingCustomerSelect($event)"></customer-search>
                                    <div class="ms-1">
                                        <contact-display *ngIf="jobModel.billingCustomer"
                                                         [contactModel]="jobModel.billingCustomer.contact"></contact-display>
                                    </div>
                                </div>

                                <div *ngIf="showAddBilling === true && jobModel.billingCustomer" class="col-12 mt-2">
                                    <div *ngIf="showCompanyName === true" class="form-group row">
                                        <div class="col-6">
                                            <input class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.firstName" placeholder="First Name" (keypress)="onNameChanged($event)" />
                                        </div>
                                        <div class="col-6">
                                            <input class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.lastName" (keypress)="onNameChanged($event)" placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div *ngIf="showCompanyName === false" class="form-group row">
                                        <div class="col-6">
                                            <input class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.firstName" [slick-validation-indicator]="jobModel.billingCustomer.contact.firstName" slickValidationType="error" placeholder="First Name" (keypress)="onNameChanged($event)" />
                                            <form-error *ngIf="!jobModel.billingCustomer.contact.firstName && isSubmitted === true">First Name is required</form-error>
                                        </div>
                                        <div class="col-6">
                                            <input class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.lastName" [slick-validation-indicator]="jobModel.billingCustomer.contact.lastName" slickValidationType="error" (keypress)="onNameChanged($event)" placeholder="Last Name" />
                                            <form-error *ngIf="!jobModel.billingCustomer.contact.lastName && isSubmitted === true">Last Name is required</form-error>
                                        </div>
                                    </div>
                                    <div *ngIf="showCompanyName === false" style="line-height: 10px; height: 10px; position: relative; top: -6px">
                                        <button type="button" class="btn btn-link" (click)="showCompanyName = !showCompanyName" style="font-size: 10px;">Show Company Name</button>
                                    </div>
                                    <div *ngIf="showCompanyName === true" class="form-group row">
                                        <div class="col-12">
                                            <input class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.companyName" [slick-validation-indicator]="jobModel.billingCustomer.contact.companyName" slickValidationType="error" placeholder="Company Name" />
                                            <form-error *ngIf="showCompanyName === true && !jobModel.billingCustomer.contact.companyName && isSubmitted === true">Company Name is required</form-error>
                                        </div>
                                    </div>
                                    <div *ngIf="showCompanyName === true" style="line-height: 10px; height: 10px; position: relative; top: -6px">
                                        <button type="button" class="btn btn-link" (click)="showCompanyName = !showCompanyName" style="font-size: 10px;">Hide Company Name</button>
                                    </div>

                                    <address [(ngModel)]="jobModel.billingCustomer.contact.defaultAddress" [showPlaceholder]="true"></address>
                                    <div *ngIf="showAlt2 === false && !jobModel.billingCustomer.contact.defaultAlt2.phoneNumber" class="form-group row" style="margin-top: -10px;">
                                        <div class="col-12 d-flex">
                                            <div class="me-1"><input type="text" class="form-control" [slick-validation-indicator]="jobModel.billingCustomer.contact.defaultCell.phoneNumber" slickValidationType="warning" [(ngModel)]="jobModel.billingCustomer.contact.defaultCell.phoneNumber" placeholder="Cell" /></div>
                                            <div class="me-1"><input type="text" class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.defaultAlt1.phoneNumber" placeholder="Alt 1" /></div>
                                            <div class="me-1"><button type="button" class="btn btn-billing" (click)="showAlt2 = true"><i class="far fa-plus"></i></button></div>
                                        </div>
                                    </div>
                                    <div *ngIf="showAlt2 === true" class="form-group row" style="margin-top: -10px;">
                                        <div class="col-12 d-flex">
                                            <div class="me-1"><input type="text" [slick-validation-indicator]="jobModel.billingCustomer.contact.defaultCell.phoneNumber" slickValidationType="warning" class="form-control me-1" [(ngModel)]="jobModel.billingCustomer.contact.defaultCell.phoneNumber" placeholder="Cell" /></div>
                                            <div class="me-1"><input type="text" class="form-control me-1" [(ngModel)]="jobModel.billingCustomer.contact.defaultAlt1.phoneNumber" placeholder="Alt 1" /></div>
                                            <div><input type="text" class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.defaultAlt2.phoneNumber" placeholder="Alt 2" /></div>
                                        </div>

                                    </div>
                                    <div class="form-group row">
                                        <div class="col-12">
                                            <input type="text" [slick-validation-indicator]="jobModel.billingCustomer.contact.defaultEmailAddress.emailAddress" slickValidationType="warning" class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.defaultEmailAddress.emailAddress" placeholder="Billing Email Address..." />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-12">
                                            <input type="text" class="form-control" [(ngModel)]="jobModel.billingCustomer.contact.secondaryEmailAddress.emailAddress" placeholder="Secondary Email Address..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slick-tab>
                </slick-tabs>
            </div>
        </div>
        <div class="col-6">
            <label>Scratch Pad</label>
            <textarea class="form-control"
                      [(ngModel)]="jobModel.scratchPad"
                      style="height: 140px"></textarea>
        </div>
    </div>

    <div class="row form-group" style="margin-top: -5px;">
        <div [ngClass]="{'col-4': useQuickbooks, 'col-8': !useQuickbooks }">
            <label>Job Name</label>
            <input type="text" [disabled]="jobModel.jobId > 0 && canEditJobName === false" class="form-control" [(ngModel)]="jobModel.jobName" [slick-validation-indicator]="jobModel.jobName" slickValidationType="error" />
            <form-error *ngIf="!jobModel.jobName && isSubmitted === true">Job Name is required</form-error>
        </div>
        <div class="col-4" *ngIf="useQuickbooks">
            <label (click)="forceQBNameUnlock()">Quickbooks Name<span *ngIf="canUpdateQuickbooksName && !jobModel.customQuickbooksName">&nbsp;&nbsp;<button class="btn btn-link" (click)="changeQuickbooksName()"><i class="fad fa-edit"></i></button></span></label>
            <input type="text" [disabled]="!canUpdateQuickbooksName || !jobModel.customQuickbooksName" class="form-control" [(ngModel)]="jobModel.quickbooksName" />
        </div>
        <div class="col-4">
            <label>Job Status</label>
            <slick-drop-down [(ngModel)]="jobModel.jobStatusId"
                             [items]="jobStatuses"
                             [disabled]="canUpdateJobStatus === false"
                             (onSelect)="onJobStatusSelect($event)"
                             allowEmpty="false">
            </slick-drop-down>
        </div>
    </div>

    <div class="row form-group">
        <div class="col-4">
            Estimator
            <slick-drop-down [(ngModel)]="jobModel.estimatorUserId"
                             [items]="estimators"
                             searchType="eachWord"
                             [validationIndicator]="jobModel.estimatorUserId"
                             validationIndicatorType="warning"
                             (onSelect)="onEstimatorSelect($event)">
            </slick-drop-down>

        </div>
        <div class="col-4">
            Production Manager
            <slick-drop-down [(ngModel)]="jobModel.productionManagerUserId"
                             [items]="productionManagers"
                             searchType="eachWord"
                             (onSelect)="onProductionManagerSelect($event)">
            </slick-drop-down>
        </div>
        <div class="col-4">
            Specialty Manager
            <slick-drop-down [(ngModel)]="jobModel.specialtyManagerUserId"
                             [items]="specialtyManagers"
                             searchType="eachWord"
                             (onSelect)="onSpecialtyManagerSelect($event)">
            </slick-drop-down>
        </div>
    </div>

    <div class="row form-group">
        <div class="col-4">
            Damage Type
            <slick-drop-down [(ngModel)]="jobModel.damageTypeId"
                             [items]="damageTypes"
                             [validationIndicator]="jobModel.damageTypeId"
                             validationIndicatorType="warning"
                             (onSelect)="onDamageTypeSelect($event)"></slick-drop-down>
        </div>

        <div *ngIf="referrals && referrals.length > 0" class="col-8">
            Referral
            <slick-drop-down [(ngModel)]="jobModel.referralUuid"
                             [items]="referrals"
                             searchType="eachWord"
                             [validationIndicator]="jobModel.referralUuid"
                             validationIndicatorType="warning"
                             (onSelect)="onReferralSelect($event)"></slick-drop-down>
        </div>
    </div>

    <div class="row form-group">
        <div class="col-4">
            <div style="float: right; margin-right: 5px;">
                <input type="checkbox" [(ngModel)]="jobModel.isSelfPay" (click)="toggleSelfPay()" />Self Pay
            </div>

            Carrier
            <slick-drop-down [(ngModel)]="jobModel.insuranceCarrierId"
                             [items]="insuranceCarriers"
                             searchType="eachWord"
                             [validationIndicator]="jobModel.insuranceCarrierId"
                             validationIndicatorType="warning"
                             [disabled]="jobModel.isSelfPay"
                             (onSelect)="onInsuranceCarrierSelect($event)"></slick-drop-down>
        </div>

        <div class="col-4">
            <div style="float: right; margin-right: 5px;">
                <input type="checkbox" [(ngModel)]="jobModel.isDeskAdjuster" />Desk Adjuster
            </div>

            Adjuster
            <div *ngIf="!jobModel.isDeskAdjuster">
                <slick-drop-down [(ngModel)]="jobModel.insuranceAdjusterId"
                                 [items]="insuranceAdjusters"
                                 listWidth="400px"
                                 searchType="eachWord"
                                 [validationIndicator]="jobModel.insuranceAdjusterId"
                                 validationIndicatorType="warning"
                                 [disabled]="jobModel.isSelfPay"
                                 (onSelect)="onInsuranceAdjusterSelect($event)"></slick-drop-down>
            </div>

            <div *ngIf="jobModel.isDeskAdjuster">
                <div class="d-flex flex-column">
                    <textarea [(ngModel)]="jobModel.deskAdjusterInfo"
                              placeholder="Desk Adjuster Info"
                              cdkTextareaAutosize
                              class="form-control"
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="3"
                              style="font-size: 14px; line-height: 16px; overflow-y: hidden;"></textarea>

                </div>
            </div>
        </div>

        <div class="col-4">

            Agent

            <slick-drop-down [(ngModel)]="jobModel.insuranceAgentId"
                             [items]="insuranceAgents"
                             listWidth="400px"
                             searchType="eachWord"
                             [validationIndicator]="jobModel.insuranceAgentId"
                             validationIndicatorType="warning"
                             [disabled]="jobModel.isSelfPay"
                             (onSelect)="onInsuranceAgentSelect($event)"></slick-drop-down>

        </div>

    </div>

    <div class="row form-group">
        <div class="col-4">
            Claim Number
            <input type="text" [(ngModel)]="jobModel.claimNumber" [slick-validation-indicator]="jobModel.claimNumber" slickValidationType="warning" class="form-control" />

        </div>
        <div class="col-4">
            Date of Loss
            <div class="input-group">
                <span class="input-group-text"><span class="far fa-calendar-alt"></span></span>
                <input slick-date-picker type="text" class="form-control" [(ngModel)]="jobModel.dateOfLoss" [slick-validation-indicator]="jobModel.dateOfLoss" slickValidationType="warning" />
            </div>
            <!--<form-error *ngIf="jobEditForm.controls.dateOfLoss?.invalid && jobEditForm.submitted">Invalid Date of Loss</form-error>-->
        </div>
        <div class="col-4">
            Year Built
            <input type="text" [(ngModel)]="jobModel.yearBuilt" [slick-validation-indicator]="jobModel.yearBuilt" slickValidationType="warning" class="form-control" />
        </div>
    </div>

    <div class="row form-group">
        <div class="col-4">
            File Number
            <input type="text" [(ngModel)]="jobModel.fileNumber" [slick-validation-indicator]="jobModel.fileNumber" slickValidationType="warning" class="form-control" />

        </div>
        <div class="col-4">
            Lien Number
            <input type="text" [(ngModel)]="jobModel.lienNumber" [slick-validation-indicator]="jobModel.lienNumber" slickValidationType="warning" class="form-control" />

        </div>
    </div>

    <div class="row form-group">
        <div class="col-12">
            Matterport Link
            <input type="text" [(ngModel)]="jobModel.matterportLink" [slick-validation-indicator]="jobModel.matterportLink" slickValidationType="warning" class="form-control" />
        </div>
    </div>

    <div class="row" *ngIf="showButtons">
        <div class="col-12 form-save-buttons">
            <button type="button" (click)="onSaveJob()" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary save-button"><i class="far fa-save"></i>&nbsp;Save</button>
            <button type="button" (click)="onSaveAndEmailJob()" [slick-button-spinner]="saveAndEmailSpinnerStatus" class="btn btn-outline-primary save-button"><i class="far fa-envelope"></i>&nbsp;Save &amp; Email</button>
            <button type="button" class="btn btn-outline-danger cancel-button" (click)="onCancelJob()"><i class="far fa-ban"></i>&nbsp;Cancel</button>
        </div>
    </div>
</div>

<concurrency-error-dialog #concurrencyErrorDialogRef></concurrency-error-dialog>
<duplicate-customer-dialog #duplicateCustomerDialogRef></duplicate-customer-dialog>

<slick-confirm-dialog #removeAllEquipmentConfirmRef [showCancelButton]="false">
    Please remove all equipment before moving out of production.
</slick-confirm-dialog>

<slick-dialog #existingCustomerDialogRef header="Existing Customer" width="400px">
    <div style="line-height: 1.2rem" class="mb-1">
        We found a possible existing customer.  Would you like to use this customer or create a new one?<br />
    </div>
    <div class="d-flex flex-column">
        <div *ngFor="let existingCustomer of existingCustomers" class="mb-1 p-2 border" style="font-size: 14px; line-height: 1rem; cursor: pointer" (click)="existingCustomerSelect(existingCustomer.customerId)">
            <b>{{existingCustomer.fullName}}</b><br />
            {{existingCustomer.primaryAddress1}}<br />
            {{existingCustomer.primaryCityStatePostal}}<br />
            <span *ngIf='existingCustomer.primaryCellPhoneNumber'>Cell: {{existingCustomer.primaryCellPhoneNumber}}<br /></span>
            <span *ngIf='existingCustomer.primaryHomePhoneNumber'>Home: {{existingCustomer.primaryHomePhoneNumber}}<br /></span>
            <span *ngIf='existingCustomer.primaryWorkPhoneNumber'>Work: {{existingCustomer.primaryWorkPhoneNumber}}<br /></span>
            <span *ngIf='existingCustomer.primaryAlt1PhoneNumber'>Alt1: {{existingCustomer.primaryAlt1PhoneNumber}}<br /></span>
            <span *ngIf='existingCustomer.primaryAlt2PhoneNumber'>Alt2: {{existingCustomer.primaryAlt2PhoneNumber}}<br /></span>
        </div>

        <div class="mb-1 p-2 border" style="font-size: 16px; line-height: 1rem; cursor: pointer; text-align: center" (click)="existingCustomerSelect(0)">
            <b>Continue with new customer</b>
        </div>
    </div>
</slick-dialog>

<not-accepted-reason-dialog [isMobile]="false" #notAcceptedReasonDialogRef></not-accepted-reason-dialog>