import { Component, ViewChild } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { GlobalsService, SleepService, AuthService, LoginService, HttpService, LookupService, TasksService } from "@services";
import { InactivityTimeoutService } from "@services/inactivity-timeout.service";
import { VersionReleaseDialogComponent } from "@shared-components/version-release-dialog"
import * as moment from 'moment';
import { TasksStore } from '@stores';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	@ViewChild("dialogRef", { static: true }) dialogRef: VersionReleaseDialogComponent;

	isLoggedIn: boolean = false;
	isCustomerForms: boolean = false;
	isSurvey: boolean = false;
	isQuickbooksOAuthResponse: boolean = false;
	isOnlinePayments: boolean = false;
	showPage: boolean = false;
	constructor(private router: Router,
		private authService: AuthService,
		private httpService: HttpService,
		private loginService: LoginService,
		private lookupService: LookupService,
		private tasksStore: TasksStore,
		private inactivityTimeoutService: InactivityTimeoutService) {
		this.isLoggedIn = GlobalsService.isLoggedIn;

		this.isOnlinePayments = GlobalsService.isOnlinePayments;

		if (this.isOnlinePayments) {
			router.events.subscribe(async (event) => {
				if (event instanceof NavigationStart) {
					if (event.url.indexOf('/online-payments') !== 0)
						window.location.href = "/#/online-payments";
				}
			});

			this.showPage = true;
			return;
		}


		// This code will trap all buttons and prevent the user from double clicking
		document.addEventListener('click', (e: MouseEvent) => {
			let buttonEl: HTMLButtonElement;

			// Go 3 elements deep to see if this is a button.  
			// The first element is most likely going to be the text of the button
			const target = <HTMLElement>e.target;
			if (target.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>target;

			const parentTarget = <HTMLElement>((target === null) ? null : target.parentElement);
			if (parentTarget && parentTarget.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>parentTarget;

			const grandparentTarget = <HTMLElement>((parentTarget === null) ? null : parentTarget.parentElement);
			if (grandparentTarget && grandparentTarget.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>grandparentTarget;

			if (buttonEl) {
				if (buttonEl.hasAttribute("disabled") ||
					buttonEl.hasAttribute("allowDoubleClick"))
					return;

				setTimeout(() => {
					if (buttonEl.querySelector(".slick-button-spinner_error"))
						return;

					buttonEl.setAttribute("disabled", 'disabled');
				});

				// If this has a slick-button-spinner, let the spinner re-enable
				if (!buttonEl.classList.contains("slick-button-spinner"))
					setTimeout(() => buttonEl.removeAttribute("disabled"), 600);
			}

		});
	}

	async ngAfterViewInit() {
		// Every time we change a page, make sure we can go there
		this.router.events.subscribe(async (event) => {
			if (event instanceof NavigationStart) {
				await SleepService.sleep();

				this.isOnlinePayments = GlobalsService.isOnlinePayments;

				if (this.isOnlinePayments) {
					if (event.url.indexOf('/online-payments') !== 0)
						window.location.href = "/#/online-payments";
					this.showPage = true;
					return;
				}


				if (window.location.href.indexOf("/customer-forms") >= 0) {
					this.showPage = true;
					this.isCustomerForms = true;
					return;
				}

				if (window.location.href.indexOf("/quickbooksOAuthResponse") >= 0) {
					this.showPage = true;
					this.isQuickbooksOAuthResponse = true;
					return;
				}

				if (window.location.href.indexOf("#/survey") >= 0) {
					this.showPage = true;
					this.isSurvey = true;
					return;
				}

				if (GlobalsService.isLoggedIn === true) {
					const lastAction = localStorage.getItem("last_action");
					if (lastAction) {
						const lastActionDate = moment(new Date(parseInt(lastAction)));
						if (moment().diff(lastActionDate, "hours") > 12) {
							await this.loginService.autoLogout();
							this.authService.clearToken();
							window.location.href = "/";
							return;
						}
					}
				}

				if (GlobalsService.isLoggedIn === false) {
					if (event.url.toLowerCase().indexOf('/login') < 0) {

						if (event.url.toLowerCase().indexOf('/reset-password') >= 0 || event.url.toLowerCase().indexOf('/forgot-password') >= 0) {
							window.location.href = `/#${event.url}`;
							this.showPage = true;
							return;
                        }

						window.location.href = '/#/login';
						return;
					}
				}

				// If they've been disabled, force a logoff
				if (GlobalsService.userInfo.isDisabled === true) {
					await this.loginService.autoLogout();
					this.authService.clearToken();
					window.location.href = "/";
					return;
				}

				// Without this, we get a little blip of the original page before we re-direct to 
				this.showPage = true;
			}
		});

		if (GlobalsService.isLoggedIn) {
			this.inactivityTimeoutService.resetInactivityTimeout();

			// Initially read from localStorage, but then clear it and read from the server
			setTimeout(() => {
				this.lookupService.clearLocalstorage();
			}, 5000);

			if (GlobalsService.userInfo.lastSeenVersion != GlobalsService.appVersion) {
				if (!GlobalsService.userInfo.lastSeenVersion || GlobalsService.userInfo.lastSeenVersion === "0")
					await this.httpService.patch(`/versionRelease/updateUserLastSeenVersion?versionNumber=${GlobalsService.appVersion}`);
				else {
					await this.dialogRef.showDialog();
					await this.httpService.patch(`/versionRelease/updateUserLastSeenVersion?versionNumber=${GlobalsService.appVersion}`);
				}
			}

			this.tasksStore.init(false);
		}
		// If not logged in, clear local storage
		else {
			this.lookupService.clearLocalstorage();
		}
	}

}
